import './physgpt.css';
import { KeyboardEventHandler, MouseEvent, MouseEventHandler, ChangeEventHandler, FormEventHandler } from 'react';
import { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuid4 } from 'uuid';

import { faPaperPlane, faSpinner, faFlag as faFlagSolid } from '@fortawesome/free-solid-svg-icons';
import { faFlag} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import physGPTServer from './api/axios';
import { getCookie } from '../util/cookie';
import { Overlay, Modal } from '../components/Modal'
import ProtectedElement from '../util/ProtectedElement';

const PHYSGPT_API_URL = '/search';
const CLEAR_CONTEXT_URL = '/clear_context';
const FLAG_ARTICLE_URL = '/flag_article';

interface Reference extends Object {
    id: string
    book_id: string
    flagged: boolean
    text: string
    page: Number
    paragraph: Number
    file_name: string
}

interface Response {
    id: string // Used for fetching references
    response: string
    type: "gpt-reply" | "user-query"
    references?: Reference[]
}

const role = getCookie("role");
const privileged = "Congratulations, you have exclusive access to the world's most knowledgeable physicist. What would you like to know?"
const unprivileged = "PhysGPT is an internal tool developed to answer questions about physics."
const defaultResponse: Response[] = [
    {
        id: "starter-response",
        response: ["admin", "editor"].includes(role) ? privileged : unprivileged,
        type: "gpt-reply"
    }
]

const PhysGPT = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [responses, setResponses] = useState<Response[]>(defaultResponse);

    const [showReason, setShowReason] = useState('');
    const [reason, setReason] = useState('');

    const [query, setQuery] = useState('');
    const queryRef = useRef<HTMLTextAreaElement>();

    const [activeResponseId, setActiveResponseId] = useState(null);

    const clearContext = async () => {
        physGPTServer.defaults.headers.post['X-Api-Key'] = getCookie('sessionKey')
        await physGPTServer.post(CLEAR_CONTEXT_URL, { "username": getCookie('username') });
        setResponses(defaultResponse);
    }

    useEffect(() => {
        // Clear context on page reload
        clearContext();
    }, [])

    const userResponse = (query: string): Response => {
        return {
            id: uuid4(),
            type: "user-query",
            response: query
        }
    };

    const ask_gpt: Function = async () => {
        if (query.length <= 3){
            queryRef.current.focus()
            return false
        }
        // Clear input
        const user_query = query;
        setQuery('');

        // Add user bubble
        let updatedResponses = [userResponse(query), ...responses]
        setResponses(updatedResponses);

        setLoading(true);

        try {
            physGPTServer.defaults.headers.post['X-Api-Key'] = getCookie('sessionKey')
            const response = await physGPTServer.post(PHYSGPT_API_URL, {
                "query": user_query,
                "username": getCookie('username')
            });

            console.log('got response:', response)

            const processedResponse = {
                type: "gpt-reply",
                ...response.data
            }

            // Update UI
            updatedResponses = [processedResponse, ...updatedResponses]
            setResponses(updatedResponses)

        } catch (e) {
            console.log('ERROR:', e);
        }

        setLoading(false);
        // set focus back to the input
        queryRef.current.focus()
    };

    const searchHandler: MouseEventHandler = () => {
        ask_gpt();
    }

    const keyPressHandler: KeyboardEventHandler = (event) => {
        if (!event.shiftKey) {
            //
            if (event.key === "Enter") {
                event.preventDefault();
                ask_gpt();
            }
        }
    };

    const changeHandler: ChangeEventHandler = (event) => {
        setQuery((event.target as HTMLTextAreaElement).value);
    }

    const createReferenceClickHandler = (responseId: string) => {
        const referenceClickHandler: MouseEventHandler = (event) => {
            if (event.target instanceof HTMLElement) {
                setActiveResponseId(responseId);
            }
            setModalOpen(true);
        };
        return referenceClickHandler;
    };

    const createReasonClickHandler = (article_id: string, book_id: string) => {
        const reasonClickHandler: MouseEventHandler = async (event: MouseEvent<SVGElement> | undefined) => {
            setLoading(true);
            // Update the values in the UI
            const newResponses = responses;
            const activeReference = newResponses.find(
                (response: Response) => response.id === activeResponseId
            )?.references.find(
                (reference: Reference) => reference.id === article_id
            )
            if (activeReference.flagged) {
                activeReference.flagged = false;
            } else {
                activeReference.flagged = true;
            }

            // Update the values in the DB
            physGPTServer.defaults.headers.post['X-Api-Key'] = getCookie('sessionKey')
            await physGPTServer.post(FLAG_ARTICLE_URL, {
                "username": getCookie('username'),
                "article_id": article_id,
                "book_id": book_id,
                "reason": reason,
                "value": activeReference.flagged
            });

            setResponses(newResponses);
            // Object reference comparission succeeds and fails to update the flag icon
            setReason('');
            setShowReason('');
            setLoading(false);
        };
        return reasonClickHandler
    }

    type ReasonProps = {
        submit: FormEventHandler
    }

    const Reason = (props: ReasonProps) => {
        const reasonChangeHandler: ChangeEventHandler = (event) => {
            console.log('reason:', reason)

            setReason((event.target as HTMLSelectElement).value);
        }

        return (
            <Overlay close={() => setShowReason('')}>
                <div className="mini modal" onClick={event => event.stopPropagation()}>
                    <h2>Flag Article</h2>
                    Please select a reason for flagging this article:
                    <select value={reason} onChange={reasonChangeHandler}>
                        <option value=""></option>
                        <option value="Bad OCR">Bad OCR</option>
                        <option value="Offensive">Offensive</option>
                        <option value="Biased">Biased</option>
                        <option value="Not relevant">Not relevant</option>
                        <option value="Lack of content">Lack of content</option>
                    </select>
                    <div className="button-row">
                        <button onClick={() => {
                            setReason('');
                            setShowReason('');
                        }}>Cancel</button>
                        <button disabled={!reason || loading} onClick={props.submit}>OK</button>
                    </div>
                </div>
            </Overlay>
        );
    }

    const ReferenceItem = (props: Reference) => {
        const reasonClickHandler: MouseEventHandler = createReasonClickHandler(props.id, props.book_id)
        return (
            <div key={`ref-${props.id}`} className="reference">
                <h3>
                    { props.file_name }
                    <span className="page-number">{ `(p.${props.page}:${props.paragraph})` }</span>
                </h3>
                <p>{props.text}</p>
                <div className="reference-links">
                    <Link target="_blank" rel="noopener noreferrer" to={`/pdf?file=${props.file_name}&page=${props.page}`}>View PDF</Link>
                    <FontAwesomeIcon
                        className="flag-icon"
                        onClick={() => {
                            if (props.flagged) {
                                // Unflag
                                reasonClickHandler(undefined);
                            } else {
                                // Flag
                                setShowReason(props.id)
                            }
                        }}
                        icon={props.flagged ? faFlagSolid : faFlag}
                        title={props.flagged ? "Unflag article" : "Flag article"}
                    />
                </div>
                {showReason === props.id ? <Reason submit={reasonClickHandler} /> : null}
            </div>
        )
    }

    return (
        // This page should validate the user and redirect if unauthenticated.
        <div className={`app-container${loading ? ' loading': ''}`}>
            {
                modalOpen ? (
                    <Overlay close={() => setModalOpen(false)}>
                        <Modal title="References" close={() => setModalOpen(false)}>
                            <div className="reference-container">
                                {
                                    responses.find(
                                        (response: Response) => response.id === activeResponseId
                                    )?.references.map(
                                        (activeReference: Reference) => <ReferenceItem {...activeReference} />
                                    )
                                }
                            </div>
                        </Modal>
                    </Overlay>
                ) : null
            }
            <div className="chat-container">
                <div className="chat-background">
                    <ProtectedElement roles={['viewer']}>
                        <section>
                            <p>
                                Currently, access to this application is restricted to members only. Email Jeremy to request GPT access.
                            </p>
                        </section>
                    </ProtectedElement>
                    {responses.map((response, index) => {
                        return (
                            <section key={`chat-${response.id}`} className={response.type}>
                                <p>{response.response}</p>
                                <div>
                                    {/* div wrapper is here so flex doesn't stretch click area */}
                                    {
                                        response.references ?
                                        <div className="link" onClick={createReferenceClickHandler(response.id)}>References</div> :
                                        null
                                    }
                                </div>
                            </section>
                        )
                    })}

                    <div className="chat-fader"></div>
                </div>
            </div>
            <div className="input-container">
                <label htmlFor="search">Ask her a question</label>
                <div className="search-wrapper">
                    <textarea id="search"
                        ref={queryRef}
                        value={query}
                        onChange={changeHandler}
                        onKeyUp={keyPressHandler}
                        className={`user-input${loading ? ' disabled' : ''}`}>
                    </textarea>
                    <ProtectedElement roles={['editor', 'admin']} defaultElement={
                        <div className='query-icon disabled'>
                            <FontAwesomeIcon
                                className='disabled'
                                icon={faPaperPlane}
                                title="Talk to an administrator for access"
                            />
                        </div>
                    }>
                        <div onClick={searchHandler} className={`query-icon${loading ? ' disabled' : ''}`}>
                            <FontAwesomeIcon
                                className={loading ? 'disabled' : ''}
                                spin={loading}
                                icon={loading ? faSpinner : faPaperPlane}
                                title="Ask GPT"
                            />
                        </div>
                    </ProtectedElement>
                </div>
            </div>
        </div>
    );
}

export default PhysGPT
