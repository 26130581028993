import { useState, MouseEvent } from 'react';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Outlet, Link } from 'react-router-dom';

import ProtectedElement from '../util/ProtectedElement';

const Spacer = () => {
    return <div className='spacer'></div>
}

const Layout = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [timer, setTimer] = useState(null);

    const menuClickHandler = (event: MouseEvent) => {
        event.stopPropagation();
        setMenuOpen(!menuOpen);
    };

    const clickHandler = () => {
        setMenuOpen(false);
    };

    const setExitTimer = (event: MouseEvent) => {
        const timerSeconds = 6;
        setTimer(setTimeout(clickHandler, timerSeconds * 1000));
    };

    const clearExitTimer = () => {
        if (timer) {
            clearInterval(timer);
        };
    };

    return (
        <div id="background" onClick={clickHandler}>
            <header className="page-title">
                <ProtectedElement defaultElement={<Spacer/>}><Spacer /></ProtectedElement>
                <div>Phys GPT</div>
                <ProtectedElement defaultElement={<Spacer/>}>
                    <div className="menu-container"
                         onMouseLeave={setExitTimer}
                         onMouseOver={clearExitTimer}
                         onClick={menuClickHandler}>
                        <FontAwesomeIcon className="menu-icon" title="Menu" icon={faBars} />
                        <div className={menuOpen ? '' : 'hidden'} >
                            <div className="menu-background">
                                <ul className="menu-items">
                                    <ProtectedElement roles={['admin']}>
                                        <Link to="/user_management"><li>Management</li></Link>
                                    </ProtectedElement>
                                    <Link to="/update_password"><li>Change password</li></Link>
                                    <Link to="/logout"><li>Logout</li></Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ProtectedElement>
            </header>
            <main>
                <Outlet />
            </main>
        </div>
    )
}

export default Layout